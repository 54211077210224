@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@450;500;600;700&display=swap');

html {
  --LI: rgb(220 220 220);
  --L0: rgb(235 235 235);
  --L1: rgb(245 245 245);
  --L2: rgb(250 250 250);

  --highContrast: rgba(0, 0, 0, 0.87);
  --lowContrast: rgba(0, 0, 0, 0.6);

  --textInvert: rgba(233, 233, 233);

  --accent: #4361e4;
  --danger: #e5534b;
  --accentLight: #e0e2f3;

  --border: 1px solid #d7dadf;
  --border-color: #e9ebf1de;
  --br: 10px;

  --awaitingDispatch: #66cf03;
  --needAttention: #e60909;
}

html.dark {
  --LI: rgb(10, 10, 15);
  --L0: rgba(25 25 25);
  --L1: rgba(35 35 35);
  --L2: rgba(45 45 45);

  --highContrast: #d9e4f0;
  --lowContrast: #adadad;
  --textInvert: rgb(0, 0, 0);

  --accent: #a2b3ff;
  --accentLight: #43435c;

  --border: 1px solid rgba(255, 255, 255, 0.08);
  --border-color: rgba(255, 255, 255, 0.04);

  --awaitingDispatch: #94c165;
  --needAttention: #a70c0d;
}
