p, span, div, li, td, tr, th, input
  color: var(--highContrast)

html
  background: var(--L0) !important
  font-size: 14px
  @media (max-width: 1000px)
    font-size: .9rem

a 
  text-decoration: none
  &:hover 
    color: var(--accent)
    
body
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  overflow: hidden


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button 
  -webkit-appearance: none
  margin: 0


/* Firefox */
input[type=number] 
  -moz-appearance: textfield


input:active, input:focus
  outline: none !important


[contentEditable=true]:empty:before
  content: attr(data-ph)
  color: var(--lowContrast)
  opacity: .5

@media print
  .noprint
    display: none !important

.App
  display: grid
  grid-template-columns: 51px auto
  height: 100vh
  @media print
    height: auto !important

.reactTooltips
  font-size: .8
  padding: 5px 8px !important

.base-container
  overflow-y: auto !important
  padding: 0 0 20px 0
  @media ( min-width: 2000px)
    margin: 0 auto
    width: 1800px


.pageLayout__header
  display: flex
  align-items: center
  padding: 10px 15px 15px
  min-height: 34px
  font-size: 1.1rem
  .pageLayout__title
    font-weight: 600
    color: var(--accent)
  .pageLayout__icon
    margin-right: .5rem
    width: 23px
    path
      color: var(--accent)
  .pageLayout__actions
    margin-left: auto

