.inCloudconIcon
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 100px
  height: 100px
  color: var(--accent)
  z-index: 100

.isDragAccept
  opacity: .1

.preorderTitle
  color: var(--accent) !important
  border: 1px solid var(--accent)
  padding: 3px 6px
  margin: 0 10px
  border-radius: var(--br)
  font-size: .9rem

.OrderLayout
  --window-padding: 0px
  position: relative
  position: relative
  display: grid
  grid-template-columns: 3fr 1fr 

  .Info
    grid-column-start: 2

  .Composition
    grid-column: 1/3
    border-bottom: var(--border)

.LeftSideContent
  display: grid
  grid-template-columns: 1fr 1fr
  height: fit-content
  width: 100%

  @media ( min-width: 2000px)
    margin: 0 auto
    max-width: 1700px

  .Composition
    grid-column-start: 1
    grid-column-end: -1

  .Docs
    border-left: var(--border)
    min-height: 100px
    grid-column-start: 2
    grid-column-end: -1
